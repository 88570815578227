<template>
  <div class="setRegionManager" style="text-align: left;">
    <van-nav-bar
        title="指定区域经理"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />
    <br /><br />
    <van-form @submit="onSubmit">
      <van-field
          readonly
          clickable
          name="area"
          :value="areaStr"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"
      />
      <van-field
          v-model="address"
          name="address"
          rows="2"
          autosize
          label="详细地址"
          type="textarea"
          maxlength="64"
          placeholder="请填写详细地址"
          show-word-limit
      />
      <van-field
          readonly
          clickable
          name="regionManager"
          :value="regionManager"
          label="区域经理"
          placeholder="点击选择区域经理"
          @click="showRegionManager = true"
      />
      <van-field v-model="storeName" name="storeName" label="门店名称" placeholder="请输入门店名称" />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>

    <van-popup v-model="showRegionManager" position="bottom">
      <van-picker
          show-toolbar
          :columns="regionManagerOptions"
          @confirm="onConfirmRegionManager"
          @cancel="showRegionManager = false"
      />
    </van-popup>

    <van-popup v-model="showArea" position="bottom">
      <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getAreaList, getRegionManagerOptions, setRegisterInfo } from "@/api/login";

export default {
  name: "SetRegionManager",
  components: {},
  data() {
    return {
      areaStr: '',
      areaArrVal: [],
      regionManager: '',
      regionManagerId: null,
      regionManagerOptions: [],
      regionManagerOptionsVal: [],
      storeName: '',
      showRegionManager: false,
      showArea: false,
      areaList: [],
      address: '',
      isLoading: false,
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.areaList = getAreaList();
    },
    onConfirm(areaArr) {
      console.log(areaArr);
      this.areaArrVal = areaArr;
      if(areaArr[1].name.startsWith(areaArr[0].name)) {
        this.areaStr = areaArr[1].name + '/' + areaArr[2].name;
      } else {
        this.areaStr = areaArr[0].name + '/' + areaArr[1].name + '/' + areaArr[2].name;
      }
      this.showArea = false;
      // 重置区域经理选择项
      let params = { provinceCode: areaArr[0].code, cityCode: areaArr[1].code, regionCode: areaArr[2].code }
      getRegionManagerOptions(params).then(response => {
        let data = response.data;
        console.log(data);
        this.regionManagerOptions = [];
        this.regionManagerOptionsVal = [];
        this.regionManager = '';
        this.regionManagerId = null;
        for(let i in data) {
          console.log(data[i]);
          let showName;
          if(data[i].nickName) {
            showName = data[i].nickName + '(' + data[i].username + ')';
          } else {
            showName = data[i].username;
          }
          this.regionManagerOptions.push(showName);
          this.regionManagerOptionsVal[showName] = data[i].userId;
        }
      });
    },
    onConfirmRegionManager(val) {
      console.log(val);
      console.log(this.regionManagerOptionsVal[val]);
      this.regionManager = val;
      this.regionManagerId = this.regionManagerOptionsVal[val];
      this.showRegionManager = false;
    },
    onSubmit(f) {
        if(this.isLoading){
            return;
        }
        this.isLoading = true;
      console.log(f);
      // TODO 验证参数
      let provinceCode = this.areaArrVal[0].code;
      let cityCode = this.areaArrVal[1].code;
      let regionCode = this.areaArrVal[2].code;
      // this.address;
      // this.regionManagerId
      // this.storeName
      let params = { provinceCode: provinceCode, cityCode: cityCode, regionCode: regionCode, address: this.address, regionManagerId: this.regionManagerId, storeName: this.storeName };
      console.log(params);
      setRegisterInfo(params).then(response => {
        console.log(response);
        // 跳至待审核
        this.$router.push({path: '/checkPending',query: { phone: this.$route.query.phone }});
        this.isLoading = false;
      });
    },
    onReturn() {
      this.$router.push({path: '/phoneInput',query: { phone: this.$route.query.phone }});
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
